import BaseRepository from "@/api/base.js";

const baseFactory = () => {
  return {
    fields: {},
    menu_section: { fields: {}, children: [] },
    install_stat_section: { fields: {}, children: [] },
    footer_section: { fields: {}, contact: {}, children: [] },
    feedback_form: { fields: {} },
    connect_dialogue_section: { fields: {}, links: { feedback_form: [] } },
    subscribe_section: { fields: {} },
    catchup_dialogue_section: { fields: {}, children: [] },
    notification_appear: {},
    notification_lang: {},
    product_category_index: {},
    product_index: {},
    person_index: {},
    clients_index: {},
    feedback_form_index: {},
    lang_code: "",
    alt_version: false,
  };
};

export default {
  state: {
    baseData: baseFactory(),
    isInit: false,
    isMainLoading: true,
    notificationDelay: false,
  },
  actions: {
    async fetchBase({ commit }) {
      try {
        const { data } = await BaseRepository.getBase();
        commit("setBaseData", data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
  getters: {
    defaultFeedbackForm(state) {
      return state.baseData.feedback_form.fields;
    },
    defaultFeedbackFormName(state) {
      return state.baseData.feedback_form.node_name || "";
    },
    getFeedbackForm(state) {
      return (id) => state.baseData.feedback_form_index[id];
    },
    getCommonFields(state) {
      return state.baseData.fields;
    },
    getWindowTitle(state) {
      return (addTitle) => {
        if (!state.baseData.fields.title) return undefined;
        return addTitle
          ? `${state.baseData.fields.title} — ${addTitle}`
          : state.baseData.fields.title;
      };
    },
    getMenu(state) {
      return state.baseData.menu_section;
    },
    getFooter(state) {
      return state.baseData.footer_section;
    },
    getConnectDialogue(state) {
      return state.baseData.connect_dialogue_section;
    },
    getPresentationDialogue(state) {
      return state.baseData.presentation_dialogue_section;
    },
    getSubscribeSection(state) {
      return state.baseData.subscribe_section;
    },
    getCatchupDialogSection(state) {
      return state.baseData.catchup_dialogue_section;
    },
    getInstallStats(state) {
      return state.baseData.install_stat_section.children || [];
    },
    getAllCategories(state) {
      return state.baseData.product_category_index;
    },
    getProductCategory(state) {
      return (categoryId) => state.baseData.product_category_index[categoryId];
    },
    getProduct(state) {
      return (productId) => state.baseData.product_index[productId];
    },
    getProductByName(state) {
      return (productName) => Object.values(state.baseData.product_index)
        .find(product => product.node_name === productName);
    },
    getPerson(state) {
      return (personId) => state.baseData.person_index[personId];
    },
    hasNotificationAppear(state) {
      return Object.entries(state.baseData.notification_appear).length > 0;
    },
    hasNotificationLang(state) {
      if (state.notificationDelay) return false;
      return Object.entries(state.baseData.notification_lang).length > 0;
    },
    getNotificationAppear(state) {
      return state.baseData.notification_appear;
    },
    getNotificationLang(state) {
      if (state.notificationDelay) return {};
      return state.baseData.notification_lang;
    },
    getClient(state) {
      return (clientId) => state.baseData.clients_index[clientId];
    },
    getLang(state) {
      return state.baseData.lang_code;
    },
    isAltVersion(state) {
      return state.baseData.alt_version;
    },
    isInit(state) {
      return state.isInit;
    },
    getMainLoading(state) {
      return state.isMainLoading;
    },
  },
  mutations: {
    setBaseData(state, payload) {
      const baseData = baseFactory();
      for (let prop in payload) {
        baseData[prop] = payload[prop];
      }
      state.baseData = baseData;

      const lastNotificationShow =
        localStorage.getItem("_NOTIF_LANG_SHOW_") || 0;
      const now = Math.floor(Date.now() / 1000);
      state.notificationDelay = lastNotificationShow > now - 1 * 3600;
      if (!state.notificationDelay) {
        localStorage.setItem("_NOTIF_LANG_SHOW_", now);
      }

      state.isInit = true;
    },
    setLang(state, payload) {
      state.baseData.lang_code = payload;
    },
    hideNotification(state) {
      state.baseData.notification_appear = {};
    },
    hideNotificationLang(state) {
      state.baseData.notification_lang = {};
    },
    setFinishMainLoading(state) {
      state.isMainLoading = false;
    },
  },
};

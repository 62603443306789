<template>
  <v-container v-if="pageLoaded" fluid class="px-0 py-0">

    <notebook class="mb-12" :main-section-intro="pageData.main_section_intro" />

    <installation-statistics />

    <product-card
      v-if="(eirId > 0) && (lang == 'ru')"
      :product-id="eirId"
      class="mb-16"
    />

    <introduct-section-type-1
      v-if="pageData.introduct_section_type_1"
      :section-data="pageData.introduct_section_type_1"
      style="min-height: 420px"
      class="section"
    />

    <favourite-products :fav-data="pageData.main_fav" with-title />

    <three-previews
      :title="pageData.main_section_news.fields.title || ''"
      :button-title="pageData.main_section_news.fields.button_all || ''"
      :to="{ name: 'NewsList' }"
    >
      <template v-slot:first>
        <preview
          :preview-info="newsItem(0)"
          bigger-xxl
          bigger-xl
          bigger-md
          @tagclick="goNewsTag"
          @previewclick="goNewsItem"
        />
      </template>

      <template v-slot:second>
        <preview
          :preview-info="newsItem(1)"
          @tagclick="goNewsTag"
          @previewclick="goNewsItem"
        />
      </template>

      <template v-slot:third>
        <preview
          :preview-info="newsItem(2)"
          @tagclick="goNewsTag"
          @previewclick="goNewsItem"
        />
      </template>
    </three-previews>

    <parallax-map :map-data="pageData.main_section_clients"></parallax-map>

    <form-communication :form-id="pageData.links.feedback_form[0] || 0" />

    <zuruck />
  </v-container>
</template>

<script>
import Notebook from "@/components/Notebook.vue";
import InstallationStatistics from "@/components/InstallationStatistics.vue";
import FavouriteProducts from "@/components/FavouriteProducts/FavouriteProducts.vue";
import ParallaxMap from "../components/ParallaxMap.vue";
import FormCommunication from "@/components/FormCommunication.vue";
import MainRepository from "@/api/main.js";
import ThreePreviews from "@/components/Preview/ThreePreviews.vue";
import Preview from "@/components/Preview/Preview.vue";
import ProductCard from "@/components/ProductCard/ProductCard.vue";
import IntroductSectionType1 from "@/components/Banners/IntroductSectionType1.vue";
import Zuruck from "@/components/Zuruck.vue";

export default {
  name: "Main",

  components: {
    Notebook,
    InstallationStatistics,
    FavouriteProducts,
    ThreePreviews,
    Preview,
    ProductCard,
    ParallaxMap,
    FormCommunication,
    IntroductSectionType1,
    Zuruck,
  },

  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(null),
    };
  },

  data() {
    return {
      pageLoaded: false,
      pageData: {
        main_section_intro: { fields: {}, children: [] },
        main_section_news: { fields: {}, children: [] },
        main_section_clients: { fields: {} },
        main_section_articles: { fields: {}, children: [] },
        main_fav: { fields: {}, children: [] },
        introduct_section_type_1: { fields: {} },
        links: { feedback_form: [] },
      },
    };
  },

  computed: {
    newsItem() {
      return (index) => this.pageData.main_section_news.children[index] || {};
    },
    eirId() {
      const product = this.$store.getters.getProductByName('eir');
      return product ? product.id : 0;
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },

  watch: {
    lang(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },

  created() {
    this.loadPage();
  },

  methods: {
    async loadPage() {
      const { data } = await MainRepository.getMain();
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");
    },

    goNewsTag(tag) {
      this.$router.push({ name: "NewsList", params: { tag } });
    },

    goNewsItem(id) {
      this.$router.push({ name: "NewsItem", params: { id } });
    },
  },
};
</script>
